import styles from "./MoreAboutMe.module.css";
import PageTransition from "../../components/PageTransition";
import { NavLink } from "react-router-dom";
const pageVariantsLeftToRight = {
  initial: { x: "100vw" },
  animate: { x: 0 },
  exit: { x: "100vw" },
};

const MoreAboutMe = () => {
  return (
    <PageTransition variant={pageVariantsLeftToRight}>
      <div className={styles.container}>
          <NavLink className={styles.link} to="/">Back to the Main Page</NavLink>
          <h1 className={styles.mainText}>WORK IN PROGRESS</h1>
      </div>
    </PageTransition>
  );
};

export default MoreAboutMe;
