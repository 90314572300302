import styles from "./Skills.module.css";

const SkillsPopup = () => {
  return (
    <div className={styles.popupContainer}>
      <div className={styles.trapezoidSkills} />
      <div className={styles.skillsPopup}>
        <ul className={styles.skillsPopupUl}>
          <li className={styles.popupTextLi}>
            <span className={styles.popupTitle}>Javascript</span> <br />
            I've been dealing with javascript for 2 years, I have a good command
            of many features such as DOM manipulation, ES6+, Asynchronous
            programming in general. I did a few small projects to improve myself
            on this subject. Some of them are a small site where you can save by
            entering the movie name, director and poster and then search for the
            movie you want using the search tool, a site that calculates
            exchange rates, a small site using yandex translate api. I have
            improved myself by making such sites.
            <div className={styles.br} />
            <span className={styles.popupTitle}>Angular</span>
            <br />
            Later, during my internship process, I learned the general structure
            of Angular and made the site my mentor gave me in a pixel perfect
            way. Thanks to this internship, I have an intermediate level of
            Angular knowledge, although not very advanced.
            <div className={styles.br} />
            <span className={styles.popupTitle}>React</span>
            <br />
            Since it is a company project, I can't give much detail, but I used
            everything except the Redux structure during the 6 months I worked
            with React, and after working, I can say that I am trying to use
            React while making projects. Although I have not used the Redux
            structure, I am technically well-versed in the Redux structure.
          </li>
          <div className={styles.br} />
          <li className={styles.popupTextLi}>
            <span className={styles.popupTitle}>HTML/CSS/SASS</span> <br />
            In the same way, I have been writing HTML and CSS for 2 years, I
            have mastered the rules (how to give a class name, how to file,
            etc.) and features. I know about SASS and SCSS and I use it in my
            own small projects to improve myself.
          </li>
          <div className={styles.br} />
          <li className={styles.popupTextLi}>
            <span className={styles.popupTitle}>Python/Django</span> <br />
            As for Python, I am generally familiar with its rules and structure.
            In the same way, I have a blog page project in order to improve
            myself and learn the operations on the service side during my time
            in Django.
          </li>
        </ul>
      </div>
    </div>
  );
};

const Skills = ({ isSkillsPopupOpen, handleClickSkills }) => {
  return (
    <div className={styles.skills}>
        <h5 className={styles.title}>SKILLS</h5>
        <div className={styles.skillsArea}>
          <ul className={styles.skillsUl}>
            <li className={styles.skillLi}>
              Javascript - <span className={styles.skillSpan}>Advanced</span>
            </li>
            <li className={styles.skillLi}>
              React <span className={styles.skillSpan}>Advanced</span>
            </li>
            <li className={styles.skillLi}>
              Angular <span className={styles.skillSpan}>Intermediate</span>
            </li>
            <li className={styles.skillLi}>
              HTML <span className={styles.skillSpan}>Advanced</span>
            </li>
            <li className={styles.skillLi}>
              CSS <span className={styles.skillSpan}>Advanced</span>
            </li>
            <li className={styles.skillLi}>
              Python <span className={styles.skillSpan}>Intermediate</span>
            </li>
            <li className={styles.skillLi}>
              Django <span className={styles.skillSpan}>Intermediate</span>
            </li>
          </ul>
        </div>
        <div
          className={
            isSkillsPopupOpen ? styles.skillsPopupContainer : styles.closedPopup
          }
        >
          <SkillsPopup />
        </div>
    </div>
  );
};

export default Skills;
