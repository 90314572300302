import styles from "./BrieflyMePage.module.css";
import PageTransition from "../../components/PageTransition";
import { NavLink } from "react-router-dom";
import Cv from "./Cv";

const pageVariantsRightToLeft = {
  initial: { x: "-100vw" },
  animate: { x: 0 },
  exit: { x: "-100vw" },
};
const BrieflyMePage = () => {
  return (
    <PageTransition variant={pageVariantsRightToLeft}>
      <div className={styles.container}>
        <div className={styles.topSide}>
          <NavLink className={styles.link} to="/">
            Back to the Main Page
          </NavLink>
        </div>

        <div className={styles.mainArea}>
          <h1 className={styles.learnMore}>You can learn more by clicking on the dashed fields.</h1>
          <Cv />
        
          <h6 className={styles.warning}>
            * Since the site is public, I did not put my phone number
            and references, you can contact me and get my full resume.
          </h6>
        </div>
      </div>
    </PageTransition>
  );
};

export default BrieflyMePage;
