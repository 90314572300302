import styles from "./PersonalDetails.module.css";
import mail from "../../../../assets/mail.png";
import linkedIn from "../../../../assets/linkedIn.png";
import location from "../../../../assets/location.png";
import emailjs from "emailjs-com";
import { useRef, useState } from "react";

const PersonalDetailsPopup = ({ handleClick }) => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_3c76u6q",
        "template_8kw99x8",
        form.current,
        "XDIfEE4Bg5GB9Bjcj"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };
  return (
    <div className={styles.popupContainer}>
      <div className={styles.personalDetailsPopup}>
        <div className={styles.closeButton}>
          <div onClick={handleClick} className={styles.close}>
            X
          </div>
        </div>
        <div className={styles.mailPopup}>
          <h5 className={styles.formMessage}>
            You can use here to contact me or ask anything.
          </h5>
          <form ref={form} onSubmit={sendEmail}>
            <div className={styles.formArea}>
              <input
                placeholder="Name"
                className={styles.formInput}
                type="text"
                name="user_name"
              />
            </div>
            <div className={styles.formArea}>
              <input
                placeholder="Email"
                className={styles.formInput}
                type="email"
                name="user_email"
              />
            </div>
            <div className={styles.formArea}>
              <textarea
                placeholder="Message"
                className={styles.textarea}
                name="message"
              />
            </div>
            <input className={styles.submitButton} type="submit" value="Send" />
          </form>
        </div>
        <div className={styles.detailsTitle}>
          Location:{" "}
          <h5 className={styles.popupText}>
            I live in Istanbul/Beşiktaş, I am suitable for hybrid or remote work
            in Istanbul, and remote for out-of-town or abroad.
          </h5>
        </div>
        <div className={styles.detailsTitle}>
          LinkedIn: <br />
          <a
            className={styles.linkedinLink}
            rel="noreferrer"
            target="_blank"
            href="https://www.linkedin.com/in/kaan-mert-7b2809208"
          >
            https://www.linkedin.com/in/kaan-mert-7b2809208
          </a>
        </div>
      </div>
    </div>
  );
};

const PersonalDetails = () => {
  const [isPersonalDetailsPopupOpen, setIsPersonalDetailsPopupOpen] =
    useState(false);
  const handleClickPersonalDetails = () => {
    setIsPersonalDetailsPopupOpen(!isPersonalDetailsPopupOpen);
    console.log("person", isPersonalDetailsPopupOpen);
  };
  return (
    <div className={styles.personalDetails}>
      <div onClick={() => setIsPersonalDetailsPopupOpen(true)}>
        <h5
          onClick={() => setIsPersonalDetailsPopupOpen(true)}
          className={styles.title}
        >
          PERSONAL DETAILS
        </h5>
        <ul className={styles.personalDetailsUl}>
          <li className={styles.li}>
            {" "}
            <img className={styles.icon} src={mail} />{" "}
            <h6>km.mertkaan@gmail.com</h6>
          </li>
          <li className={styles.li}>
            {" "}
            <img className={styles.icon} src={location} />{" "}
            <h6>Beşiktaş / İstanbul</h6>
          </li>
          <li className={styles.li}>
            {" "}
            <img className={styles.icon} src={linkedIn} />{" "}
            <h6 className={styles.linkedIn}>
              https://www.linkedin.com/in/kaan-mert-7b2809208
            </h6>
          </li>
        </ul>
      </div>

      <div className={styles.personalDetailsPopupContainer}>
        <div
          className={
            isPersonalDetailsPopupOpen
              ? styles.popupContainer1
              : styles.closedPopup
          }
        >
          <PersonalDetailsPopup handleClick={handleClickPersonalDetails} />
        </div>
      </div>
    </div>
  );
};

export default PersonalDetails;
