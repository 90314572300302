import { motion } from "framer-motion";
import styles from "./PageTransition.module.css"

const pageTransitionDuration = {
  type: "tween",
  ease: "easeIn",
  duration: 0.5,
};

const pageStyle = {
  position: "absolute",
};

const PageTransition = ({ children, variant }) => {
 
  return (
    <motion.div
    className={styles.container}
      style={pageStyle}
      variants={variant}
      initial="initial"
      animate="animate"
      exit="exit"
      transition={pageTransitionDuration}
    >
      {children}
    </motion.div>
  );
};

export default PageTransition;
