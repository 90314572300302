import styles from "./LandingPage.module.css";
import Sides from "./Sides";
import PageTransition from "../../components/PageTransition";


const pageVariants = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
};

const LandingPage = () => {
  return (
    <PageTransition variant={pageVariants}
    >
      <div className={styles.container}>
        <Sides text="KAAN MERT" side="left" />

        <div className={styles.line} />
        <Sides text="JUNIOR FRONT-END DEVELOPER" side="right" />
      </div>
    </PageTransition>
    
  );
};

export default LandingPage;
