import styles from "./Cv.module.css";
import pp from "../../../assets/kaanMert.jpeg";
import { useRef, useState } from "react";
import AboutMe from "./AboutMe";
import Experience from "./Experience";
import PersonalDetails from "./PersonalDetails";
import Skills from "./Skills";

const Cv = () => {
  const [isAboutmePopupOpen, setIsAboutmePopupOpen] = useState(false);
  const [is6HivePopupOpen, setIs6HivePopupOpen] = useState(false);
  const [isInnovaPopupOpen, setIsInnovaPopupOpen] = useState(false);
  const [isSkillsPopupOpen, setIsSkillsPopupOpen] = useState(false);

  const handleClickAboutme = () => {
    setIsAboutmePopupOpen(!isAboutmePopupOpen);
    if (is6HivePopupOpen) {
      setIs6HivePopupOpen(false);
    }
    if (isInnovaPopupOpen) {
      setIsInnovaPopupOpen(false);
    }
    if (isSkillsPopupOpen) {
      setIsSkillsPopupOpen(false);
    }
  };

  const handleClick6Hive = () => {
    setIs6HivePopupOpen(!is6HivePopupOpen);
    if (isAboutmePopupOpen) {
      setIsAboutmePopupOpen(false);
    }
    if (isInnovaPopupOpen) {
      setIsInnovaPopupOpen(false);
    }
    if (isSkillsPopupOpen) {
      setIsSkillsPopupOpen(false);
    }
  };
  const handleClickInnova = () => {
    setIsInnovaPopupOpen(!isInnovaPopupOpen);
    if (isAboutmePopupOpen) {
      setIsAboutmePopupOpen(false);
    }
    if (is6HivePopupOpen) {
      handleClick6Hive(false);
    }
    if (isSkillsPopupOpen) {
      setIsSkillsPopupOpen(false);
    }
  };
  const handleClickSkills = () => {
    setIsSkillsPopupOpen(!isSkillsPopupOpen);
    if (isAboutmePopupOpen) {
      setIsAboutmePopupOpen(false);
    }
    if (is6HivePopupOpen) {
      setIs6HivePopupOpen(false);
    }
    if (isInnovaPopupOpen) {
      setIsInnovaPopupOpen(false);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.top}>
        <div className={styles.topLeft}>
          <h3 className={styles.name}>KAAN MERT</h3>
          <h4 className={styles.job}>JUNIOR FRONTEND DEVELOPER</h4>
        </div>
        <div className={styles.topRight}>
          <img className={styles.pp} src={pp} />
        </div>
      </div>
      <div className={styles.bottom}>
        <div className={styles.bottomLeft}>
          <div onClick={handleClickAboutme}>
            <AboutMe
              isAboutmePopupOpen={isAboutmePopupOpen}
              handleClick={handleClickAboutme}
            />
          </div>
          <div
            onClick={() => {
              handleClick6Hive();
              handleClickInnova();
            }}
          >
            <Experience
              isInnovaPopupOpen={isInnovaPopupOpen}
              is6HivePopupOpen={is6HivePopupOpen}
              handleClick={handleClick6Hive}
            />
          </div>
        </div>
        <div className={styles.bottomRight}>
          <div>
            <PersonalDetails />
          </div>

          <div className={styles.education}>
            <h5 className={styles.title}>EDUCATION</h5>
            <ul className={styles.educationUl}>
              <li className={styles.educationLi}>
                <h6>Bahcesehir University - Computer Engineering </h6>
                <span className={styles.educationSpan}>2018 - 2022</span>
              </li>
              <li className={styles.educationLi}>
                <h6>Sakarya Anadolu Lisesi </h6>
                <span className={styles.educationSpan}>2013 - 2017</span>
              </li>
            </ul>
          </div>
          <div className={styles.languageSkills}>
            <h5 className={styles.title}>LANGUAGE SKILLS</h5>
            <div className={styles.languageArea}>
              <h6>English</h6>
              <span className={styles.languageSpan}>B2</span>
            </div>
          </div>
          <div>
            <div onClick={handleClickSkills}>
              <Skills
                isSkillsPopupOpen={isSkillsPopupOpen}
                handleClick={handleClickSkills}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cv;
