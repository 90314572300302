import { useState } from "react";
import { NavLink } from "react-router-dom";
import styles from "./Button.module.css";

const Button = ({ buttonName, buttonLink, buttonClass, spanClass,onClick }) => {
 
  

  return (
    <button onClick={onClick} className={styles.buttonContainer}>
      <NavLink to={buttonLink} className={`${styles.button} ${buttonClass}`}>
        <span className={`${styles.span} ${spanClass}`}>{buttonName}</span>
      </NavLink>
    </button>
  );
};

export default Button;
