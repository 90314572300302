import { BrowserRouter } from "react-router-dom";
import Navigator from "./navigations";

function App() {
  return (
    <BrowserRouter>
      <Navigator/>
    </BrowserRouter>
  );
}

export default App;
