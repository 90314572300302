import styles from "./Sides.module.css";
import Button from "../../../components/Button";
import arrowL from "../../../assets/arrowL.png";
import arrowR from "../../../assets/arrowR.png";



const Sides = ({ side, text,handleClick}) => {
 
  return (
    <div  className={side === "left" ? styles.left : styles.right}>
      <div className={side === "left" ? styles.topLeft : styles.topRight}>
        <h3 className={styles.text}>{text}</h3>
      </div>
      <div className={styles.middle}>
        {side === "left" ? (
          <h5 className={styles.contentL}>
            If you want to know about me quickly, choose this side.
          </h5>
        ) : (
          <h5 className={styles.contentR}>
            WORK IN PROGRESS
          </h5>
        )}
      </div>

      <div className={styles.bottom}>
        {side === "left" ? (
          <img className={styles.arrow} src={arrowL} />
        ) : (
          <img className={styles.arrow} src={arrowR} />
        )}

        <Button
          onClick={handleClick}
          buttonLink={side === "left" ? "/BrieflyMe" : "/MoreAboutMe"}
          spanClass={
            side === "left" ? styles.leftSpanClass : styles.rightSpanClass
          }
          buttonClass={side === "left" ? styles.leftButton : styles.rightButton}
          buttonName={side === "left" ? "Briefly Me" : "More About Me"}
        />
      </div>
      <div className={styles.bottom2}></div>
    </div>
  );
};

export default Sides;
