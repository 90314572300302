import { useLocation, Route, Routes } from "react-router-dom";
import BrieflyMePage from "../screens/BrieflyMePage";
import LandingPage from "../screens/LandingPage";
import { AnimatePresence } from "framer-motion";
import MoreAboutMe from "../screens/MoreAboutMe";

const Navigations = () => {
  const location = useLocation();
  return (
    <div style={{overfloewX:"hidden", position: "relative"}}>
      <AnimatePresence>
        <Routes location={location} key={location.pathname}>
          <Route path="/" element={<LandingPage />} />
          <Route path="/BrieflyMe" element={<BrieflyMePage />} />
          <Route path="/MoreAboutMe" element={<MoreAboutMe />} />
        </Routes>
      </AnimatePresence>
    </div>
  );
};

export default Navigations;
