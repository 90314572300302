import styles from "./Experience.module.css";

const SixHivePopup = () => {
  return (
    <div className={styles.popupContainer}>
      <div className={styles.sixHivePopup}>
        <h5 className={styles.popupTitle}>6Hive</h5>
        <h5 className={styles.popupText}>
          I started by learning the React structure and all its features during
          the process I spent in 6Hive, then I took part in all the front-end
          processes of one of the company's applications, together with a
          teammate, and I benefited from almost all the features of React.
          During this project, I learned to use git and worked with Kanban
          methodology.
        </h5>
      </div>
    </div>
  );
};

const InnovaPopup = () => {
    return (
      <div className={styles.popupContainer}>
        <div className={styles.innovaPopup}>
          <h5 className={styles.popupTitle}>Innova</h5>
          <h5 className={styles.popupText}>
            In the 3 months I worked at Innova, I learned the general structure of
            Angular and how to develop the front side of a project with Angular,
            working with the team and working with notions. In the last part of my
            internship, I got an idea by making a simple introduction to the Ionic
            framework.
          </h5>
        </div>
      </div>
    );
  };

const Experience = ({ is6HivePopupOpen, handleClick6Hive, isInnovaPopupOpen, handleClickInnova }) => {
  const ExperienceData = [
    {
      popup: <SixHivePopup/>,
      title: "INTERNSHIP/ WORK EXPERIENCE",
      company: "6Hive - Front-End Developer",
      experienceDate: "2021 July - December & 2022 March - September",
      li1: "Doing all front-end operations of a comprehensive website using javascript and react.",
      li2: "During the project, I gained experience against such applications by using notion and slack.",
      li3: "Reinforcing team harmony by working with teammates during the whole project process.",
    },
    {
      popup: <InnovaPopup/>,
      company: "Innova Bilişim - Front-End Developer Internship",
      experienceDate: "2021 December - 2022 February",
      li1: "Creating website front-end with javascript and angular.",
      li2: "Fulfilling the tasks given by my superior with teamwork.",
      li3: "Doing small tasks to learn and improve Ionic framework basics.",
    },
  ];
  return (
    <div className={styles.experience}>
      {ExperienceData.map((item, index) => (
        <div key={index}>
          <div
            className={ item.company === "6Hive - Front-End Developer" ?
              is6HivePopupOpen
                ? styles.sixHivePopupContainer
                : styles.closedPopup
                :
                isInnovaPopupOpen
                ? styles.innovaPopupContainer
                : styles.closedPopup
            }
          >

            {item.popup}
          </div>

          <h5 className={styles.title}>{item.title}</h5>

          <div className={styles.experienceContainer}>
            <h4 className={styles.company}>{item.company}</h4>
            <h5 className={styles.experienceDate}>{item.experienceDate}</h5>
            <ul className={styles.leftSideUlExperience}>
              <li className={styles.leftSideLi}>{item.li1}</li>
              <li className={styles.leftSideLi}>{item.li2}</li>
              <li className={styles.leftSideLi}>{item.li3}</li>
            </ul>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Experience;
