import styles from "./AboutMe.module.css";

const AboutMePopup = () => {
  return (
    <div className={styles.popupContainer}>
      <div className={styles.aboutMePopup}>
        <h5 className={styles.popupTitle}>About Me</h5>
        <h5 className={styles.popupText}>
          Hi, I've been dealing with pure javascript and frontend for about 2
          years. One of the biggest reasons why I chose this field is that I
          like to see the changes I have made instantly. Making a website seems
          like a hobby to me rather than working, and even my spare time is a
          part of my day to visit sites like awwwards.com :). After dealing with
          pure javascript in my first year, I improve myself by writing Angular
          in Innova for the first 3 months of the last 9 months and then writing
          React for 6 months in 6Hive. For now, I'm focused on React.
        </h5>
        <br />
        <h5 className={styles.popupText}>
          Talking about my personal characteristics, it is very comfortable for
          me to communicate and get along with people. It is also easy to work
          as a team because I get along very well with people. In general, I try
          to follow new technologies as much as possible by reading articles or
          examining different projects. My English is at B2 level, my language
          of education has been English throughout my university life, so I do
          not have any problems with English. I'm graduating next month
          (September 2022). For the future, I plan to learn the backend side and
          become a fullstack. Likewise, I have an interest and effort in app
          development.
        </h5>
      </div>
      <div className={styles.trapezoidAboutMe} />
    </div>
  );
};

const AboutMe = ({ isAboutmePopupOpen, handleClickAboutme }) => {
  const AboutmeData = [
    {
      title: "About Me",
      li1: "Effective communication and time management skills.",
      li2: "Ability to easily adapt to and learn new technologies.",
      li3: "Ability to work in an organized, analytical and detail-oriented manner.",
      li4: "Good written and verbal English communication skill.",
      li5: " Passion for Web Development(and browsing sites) and App Development.",
    },
  ];
  return (
    <div className={styles.aboutMeContainer}>
      <div
        className={
          isAboutmePopupOpen ? styles.aboutMePopupContainer : styles.closedPopup
        }
      >
        <AboutMePopup />
      </div>
      {AboutmeData.map((item, index) => (
        <div key={index} className={styles.aboutMe}>
          <h5 className={styles.title}>{item.title}</h5>
          <div className={styles.aboutMeArea}>
            <ul className={styles.leftSideUlAboutme}>
              <li className={styles.leftSideLi}>
                {item.li1}
              </li>
              <li className={styles.leftSideLi}>
              {item.li2}
              </li>
              <li className={styles.leftSideLi}>
              {item.li3}
              </li>
              <li className={styles.leftSideLi}>
              {item.li4}
              </li>
              <li className={styles.leftSideLi}>
              {item.li5}
              </li>
            </ul>
          </div>
        </div>
      ))}
    </div>
  );
};

export default AboutMe;
